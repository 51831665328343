import React from 'react'

const ProjectCard = (props) => {

    const {project} = props;
    const {title, description, imageUrl, url} = project;

    return (
        <div className={`group cursor-default flex flex-col w-full xl:w-8/12 h-content py-6 px-4 rounded-3xl my-4 bg-transparent hover:bg-blau-mig/60 dark:hover:bg-blau-clar border-solid border-2 border-blau-mig dark:border-blau-clar transition-all ${title === "Skyreader" ? "self-end" : ""}`}>
            <img src={imageUrl} alt={imageUrl} className="w-full"/>
            <div className="w-full flex flex-col md:flex-row mt-2 gap-2">
                <div className="flex flex-col gap-1 px-4 my-2">
                    <h5 className="text-blau-fosc/70 dark:text-blau-molt-clar group-hover:text-gris-clar/80 text-xl font-bold">{title}</h5>
                    <p className="text-gris-mig dark:text-blau-molt-clar/70 group-hover:text-gris-clar/60 text-sm">{description}</p>
                </div>
                <a className="self-center mr-5 text-center mt-2 px-10 py-2 leading-5 md:leading-normal md:px-6 md:py-2 md:w-60 md:h-11 rounded-xl border-solid border border-gris-mig/50 hover:border-solid hover:border hover:border-gris-mig/40 bg-gris-clar/90 text-blau-fosc font-bold hover:shadow-lg transition-all cursor-pointer" href={url}>Live demo</a>
            </div>
        </div>
    )
}

export default ProjectCard