import React from 'react';
import upcImage from '../../assets/upc.png';
import nuclioImage from '../../assets/nuclio.png';
import {FaGraduationCap} from 'react-icons/fa';

const EducationTitles = () => {
    return (
        <div className="w-100 xl:w-6/12 flex flex-col gap-5">
            <div className="w-100 rounded-xl xl:rounded-3xl bg-gris-clar dark:bg-gris-clar/90">
                <div className="flex">
                    <div className="flex justify-center items-center rounded-tl-xl xl:rounded-tl-3xl bg-blau-mig/90 text-gris-clar text-3xl h-12 xl:h-16 w-2/12 z-1"><FaGraduationCap /></div>
                    <div className="flex pl-3 items-center text-xl xl:text-2xl font-semibold h-12 xl:h-16 w-10/12 bg-blau-mig/30  rounded-tr-xl xl:rounded-tr-3xl text-blau-fosc">University degree</div>
                </div>
                <div className="flex text-center xl:text-left flex-col px-8 py-5">
                    <h5 className="text-blau-fosc text-xl font-semibold">Graduate of Telecommunications and Engineering Services</h5>
                    <p className="text-upc text-sm mt-2">Specialization in telematics engineering</p>
                    <span className="text-xs text-gris-mig mt-2">2017 - 21</span>
                    <img className="hidden xl:block self-end" width="250px" alt="educational-center" src={upcImage} />
                </div>
            </div>
            <div className="w-100 rounded-xl xl:rounded-3xl bg-gris-clar dark:bg-gris-clar/90">
                <div className="flex">
                    <div className="flex justify-center items-center rounded-tl-xl xl:rounded-tl-3xl bg-blau-mig/90 text-gris-clar text-3xl h-12 xl:h-16 w-2/12 z-1"><FaGraduationCap /></div>
                    <div className="flex pl-3 items-center text-xl xl:text-2xl font-semibold h-12 xl:h-16 w-10/12 bg-blau-mig/30 rounded-tr-xl xl:rounded-tr-3xl text-blau-fosc">Master degree</div>
                </div>
                <div className="flex text-center xl:text-left flex-col px-8 py-5">
                    <h5 className="text-nuclio text-xl font-semibold">Master on Full Stack Web Developement</h5>
                    <span className="text-xs text-gris-mig mt-2">2021 - 22</span>
                    <img className="hidden xl:block self-end mr-16" width="150px" alt="educational-center" src={nuclioImage} />
                </div>
            </div>
        </div>
    )
}

export default EducationTitles