import React, { useEffect, useState } from 'react';
import spacetime from 'spacetime';
import bloobirds from '../../assets/bloobirds_logo.png';

const ExperienceTimer = () => {

  const [timer, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { diff } = spacetime().since(new Date("2022-05-09 09:00"));

  return (
      <div className='w-full xl:w-7/12 mt-32 xl:mt-0 xl:mb-32 flex flex-col items-center mx-auto mb-16 dark:bg-gris-clar dark:rounded-3xl py-10 px-10 xl:px-16'>
        <div className='text-blau-fosc' >{timer.toDateString()}</div>
          <h4 className='self-start text-xl text-gris-mig mb-5'>Working at </h4>
          <img src={bloobirds} alt="Bloobirds logo" className="w-full"/>
          <span className='text-lg self-start text-gris-mig mt-3'>for</span>
          <div className='w-full flex-col  md:flex gap-2 justify-end'>
            <h3 className='text-blau-bloobirds self-end text-3xl'>{diff.years} year,</h3>
            <h3 className='text-blau-bloobirds self-end text-3xl'>{diff.months} months,</h3>
            <h3 className='text-blau-bloobirds self-end text-3xl'>{diff.days} days,</h3>
            <h3 className='text-blau-bloobirds self-end text-xl'>{diff.hours} hours,</h3>
            <h3 className='text-blau-bloobirds self-end text-xl'>{diff.minutes} minutes,</h3>
            <h3 className='text-blau-bloobirds self-end text-xl'>{diff.seconds} seconds</h3>
          </div>
      </div>
  )
}

export default ExperienceTimer