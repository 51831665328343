import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';

const Header = () => {
    return (
        <header className="h-3/4 xl:h-screen py-14 md:py-24 pb-32 xl:py-56 xxl:py-64 my-0 text-center md:text-left">
            <h2 className="text-blau-clar dark:text-blau-molt-clar text-sm sm:text-lg">Hi, my name is</h2>
            <h1 className="md:pr-96 text-blau-fosc dark:text-gris-clar text-md sm:text-7xl">Marc Cuesta Martínez</h1>
            <h5 className="text-gris-mig dark:text-gris-clar/80">Fullstack Web Developer</h5>
            <CTA />
            <div>
                <HeaderSocials />
                <a href="#contact" className="hidden md:flex h-28 text-center text-blau-clar dark:text-blau-molt-clar underline underline-offset-2 absolute rotate-90 right-48 top-96">Scroll Down</a>
            </div>
        </header>
    )
}

export default Header;