import React from 'react';
import {FaGithub} from 'react-icons/fa';
import {FiInstagram} from 'react-icons/fi';
import {FaLinkedin} from 'react-icons/fa';

const Footer = () => {
    return (
        <section id="footer" className="flex flex-col gap-8 xl:gap-16 content-center items-center h-content w-full bg-gris-clar backdrop-blur-xl py-16">
            <div className="flex gap-6 xl:gap-16">
                <a href="#home" className="text-md text-blau-fosc">Home</a>
                <a href="#about" className="text-md text-blau-fosc">About</a>
                <a href="#experience" className="text-md text-blau-fosc">Experience</a>
                <a href="#portfolio" className="text-md text-blau-fosc">Portfolio</a>
            </div>
            <div className="flex gap-3 xl:gap-4">
                <a href="https://github.com/marccuesta99" className="w-10 h-10 p-2 bg-blau-fosc rounded-md text-2xl text-gris-clar"><FaGithub /></a>
                <a href="https://www.linkedin.com/in/marc-cuesta-mart%C3%ADnez-1150111a4" className="w-10 h-10 p-2 bg-blau-fosc rounded-md text-2xl text-gris-clar"><FaLinkedin /></a>
                <a href="https://www.instagram.com/marccuestaa/" className="w-10 h-10 p-2 bg-blau-fosc rounded-md text-2xl text-gris-clar"><FiInstagram/></a>
            </div>
            <p className="text-blau-fosc/80 text-xs xl:text-sm">© Marc Cuesta. All rights reserved</p>

        </section>
    )
}

export default Footer