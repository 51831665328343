import React, {useEffect, useContext, useState} from 'react';
import {IoCheckmarkDoneCircleOutline} from 'react-icons/io5';
import AlertContext from '../context/AlertContext';

const Alert = () => {

    const {alert, setAlert} = useContext(AlertContext);
    const [visible, setVisible] = useState(alert.visible);

    useEffect(() => {
        setVisible(alert.visible);
    }, [alert.visible])

    useEffect(() => {
        if (alert.visible) {
            setTimeout(() => {
                setAlert({
                    ...alert, 
                    visible: false,
                });
            }, 3000) 
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${!visible ? "opacity-0" : "opacity-100"} transition-all font-sans w-content ${alert.type==="success" ? "bg-alert-success/70 dark:bg-alert-success/90 text-alert-success-text" : "bg-alert-error/70 dark:bg-alert-error/90 text-alert-error-text"} py-3 md:py-3 px-3 md:px-7 fixed z-10 left-2/4 -translate-x-2/4 top-8 flex align-center content-center gap-4 rounded-full backdrop-blur-xl shadow-md `}>
            <span className="text-lg md:text-2xl"><IoCheckmarkDoneCircleOutline /></span>
            <p className="my-auto text-xs md:text-sm w-40 md:w-64">{alert.message}</p>
        </div>
    )
}

export default Alert