import React from 'react';
import SkillsBoxList from './SkillsBoxList';
import ToolsBoxList from './ToolsBoxList';

const Experience = () => {

    const frontendSkills = [
        {
            skill: "React",
            level: "Advanced",
        },
        {
            skill: "HTML",
            level: "Advanced",
        },
        {
            skill: "CSS",
            level: "Advanced",
        },
        {
            skill: "Tailwind",
            level: "Advanced",
        },
        {
            skill: "Bootstrap",
            level: "Advanced",
        },
        {
            skill: "ES6",
            level: "Advanced",
        },
        {
            skill: "TypeScript",
            level: "Beginner",
        },
        {
            skill: "NextJS",
            level: "Beginner",
        }
    ]

    const backendSkills = [
        {
            skill: "Express",
            level: "Advanced",
        },
        {
            skill: "Node",
            level: "Advanced",
        },
        {
            skill: "PHP",
            level: "Advanced",
        },
        {
            skill: "Symfony",
            level: "Advanced",
        },
        {
            skill: "Docker",
            level: "Beginner",
        },
        {
            skill: "MySQL",
            level: "Advanced",
        },
        {
            skill: "MongoDB",
            level: "Beginner",
        }
    ]

    const tools = [
        {
            skill: "Git",
            level: "Advanced",
        },
        {
            skill: "VSCode",
            level: "Advanced",
        },
        {
            skill: "JetBrains",
            level: "Advanced",
        },
        {
            skill: "Figma",
            level: "Advanced",
        }
    ]

    return (
        <section id="experience" className="h-content pt-10 pb-20 md:py-20">
            <h4 className="text-blau-clar dark:text-blau-molt-clar text-center">What Skills do I Have</h4>
            <h2 className="text-blau-fosc dark:text-gris-clar font-bold text-center">My Experience</h2>

            <div className="flex-col mt-10">
                <div className="flex flex-col lg:flex-row gap-2">
                    <SkillsBoxList title="Frontend Developement" skills={frontendSkills} />
                    <SkillsBoxList title="Backend Developement" skills={backendSkills} />
                </div>
                <ToolsBoxList title="Tools" skills={tools} />
            </div>

        </section>
    )
}

export default Experience