import React from 'react';
import {FaGithub} from 'react-icons/fa';
import {FiInstagram} from 'react-icons/fi';
import {FaLinkedin} from 'react-icons/fa';


const HeaderSocials = () => {
    return (
        <div className="hidden xl:flex flex-col conten-center gap-2 absolute left-40 top-96">
            <a className="w-8 h-8 text-blau-clar dark:text-blau-molt-clar/90" href="https://www.linkedin.com/in/marc-cuesta-mart%C3%ADnez-1150111a4"><FaLinkedin size="1.5rem"/></a>
            <a className="w-8 h-8 text-blau-clar dark:text-blau-molt-clar/90" href="https://github.com/marccuesta99"><FaGithub size="1.5rem"/></a>
            <a className="w-8 h-8 text-blau-clar dark:text-blau-molt-clar/90" href="https://www.instagram.com/marccuestaa/"><FiInstagram size="1.5rem"/></a>
        </div>
    )
}

export default HeaderSocials