import React from 'react';
import EducationTitles from './EducationTitles';
import {RiMacbookLine} from 'react-icons/ri';
import {AiOutlineProject} from 'react-icons/ai';
import ExperienceBox from './ExperienceBox';

const About = () => {
    return (
        <section id="about" className="h-content pt-10 pb-20 md:py-20">
            <h4 className="text-blau-clar dark:text-blau-molt-clar text-center">Get to know</h4>
            <h2 className="text-blau-fosc dark:text-gris-clar font-bold text-center">About me</h2>

            <div className="w-100 flex flex-col xl:flex-row justify-around mt-14 xl:mt-16 gap-10">
                <EducationTitles />
                <div className="w-100 xl:w-5/12 flex flex-col gap-5 justify-around">
                    <div className="w-100 flex justify-center gap-3 xl:gap-10">
                        <ExperienceBox title={"Experience"} subtitle={"+1 year of coding"} logo={<RiMacbookLine />} />
                        <ExperienceBox title={"Projects"} subtitle={"+6 diferent projects"} logo={<AiOutlineProject />} />
                    </div>
                    <p className="w-100 text-center xl:text-left text-xs xl:text-sm text-blau-fosc dark:text-blau-molt-clar">Hi! I'm a graduate of Telecommunications and Engineering Services at UPC and Full Stack Web Developement at Nuclio Digital School. I possess good communication and collaboration skills to lead and work in concert with diverse groups effectively. I'm reliable and able to excel under pressure. Currently, I'm eager to meet new challenges and overcome them by gaining experience.</p>
                    <a className="self-center text-center xl:text-left w-36 px-6 py-2 rounded-2xl border-solid border-2 border-gris-mig/60 hover:border-solid hover:border-2 hover:border-gris-mig/40 bg-gris-clar/90 text-blau-fosc font-bold transition-all" href="#contact">Contact me!</a>
                </div>
            </div>
        </section>
    )
}

export default About