import React from 'react'

const ContactBox = (props) => {

    const {options} = props;
    const {icon, title, contact, url} = options;

    return (
        <div className="flex flex-col w-48 xl:w-60 h-40 xl:h-44">
            <div className="w-full h-full flex flex-col justify-center items-center rounded-t-3xl bg-blau-fosc/60">
                <div className="text-2xl 2xl:text-4xl mt-3 text-blau-molt-clar">{icon}</div>
                <h4 className="font-bold text-lg md:text-xl 2xl:text-2xl text-gris-clar/90 mt-3">{title}</h4>
                <h5 className="font-medium text-xs md:text-sm 2xl:text-md text-blau-molt-clar/80">{contact}</h5>
            </div>
            <a className="w-full h-12 text-center p-2 rounded-b-3xl font-semibold text-gris-clar text-sm xl:text-lg bg-blau-fosc/80" href={url}>Send a message!</a>
        </div>
    )
}

export default ContactBox