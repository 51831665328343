import React, { useState, useEffect } from 'react';
import Experience from './components/experience/Experience';
import Header from './components/header/Header';
import ExperienceTimer from './components/experience/ExperienceTimer';
import Nav from './components/nav/Nav';
import About from './components/about/About';
//import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
//import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Topbar from './components/topbar/Topbar';
import Alert from './components/alert/Alert';
import AlertContext from './components/context/AlertContext';


const App = () => {

    const [dark, setDark] = useState(localStorage.theme === 'dark' ? true : false);
    const [alert, setAlert] = useState({
        message: "",
        visible: false,
        type: ""
    });

    useEffect(() => {
        if (localStorage.theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [dark])

    return (
        <AlertContext.Provider value={{alert, setAlert}}>
            <Alert/>
            <div className="bg-fons-clar dark:bg-fons-fosc transition-colors">
                <Topbar dark={dark} setDark={setDark}/>
                <div className="mx-6 sm:mx-16 md:mx-40 2xl:mx-48  md:px-2 lg:px-10">
                    <Header />
                    <ExperienceTimer />
                    <Nav />
                    <About />
                    <Experience />
                    <Portfolio />
                    <Contact />
                </div>
                <Footer />
            </div>
        </AlertContext.Provider>
    )
}

export default App