import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import elnotasImg from "../../assets/elnotas.png";
import elnotasImgResp from "../../assets/elnotas_resp.png";
import skyreaderImg from "../../assets/skyscanner.png";

const Portfolio = () => {

    const elNotas = {
        title: "ElNotas",
        description:"Responsive webapp that helps you calculate the grade needed to pass the subject. It also allows you to manage your subjects so you can make multiple queries.",
        imageUrl: elnotasImg,
        url: "https://elnotas.es"
    }

    const elNotasResp = {
        title: "ElNotas",
        description:"Responsive webapp that helps you calculate the grade needed to pass the subject. It also allows you to manage your subjects so you can make multiple queries.",
        imageUrl: elnotasImgResp,
        url: "https://elnotas.es"
    }

    const skyreader = {
        title: "Skyreader",
        description: "Application developed by a team of 4 members according to the SCRUM methodology. It is a recreation of the existing Skyscanner",
        imageUrl: skyreaderImg,
        url: "https://sky-reader.netlify.com"
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth > 1024 ? false : true);

    useEffect(() => {
        const checkWidth = () => {
            if(window.innerWidth > 1024) {
                setIsMobile(false)
            } else {
                setIsMobile(true)
            }
        }
        window.addEventListener("resize", checkWidth)
      }, [])

    return (
        <section id="portfolio" className="h-content pt-10 pb-20 md:py-20">
            <h4 className="text-blau-clar dark:text-blau-molt-clar text-center">My recent work</h4>
            <h2 className="text-blau-fosc dark:text-gris-clar font-bold text-center">Portfolio</h2>

            <div className="flex flex-col w-full mt-10">
                {isMobile && <ProjectCard project={elNotasResp} />}
                {!isMobile && <ProjectCard project={elNotas} />}
                <ProjectCard project={skyreader} />
            </div>

            <p className="text-center md:text-left italic text-blau-fosc/70 dark:text-blau-molt-clar text-sm mt-4">More coming soon...</p>

        </section>
    )
}

export default Portfolio