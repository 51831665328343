import React from 'react';
import {FiMail} from 'react-icons/fi';
import {AiOutlineWhatsApp} from 'react-icons/ai';
import {FaLinkedin} from 'react-icons/fa';
import ContactBox from './ContactBox';
import ContactForm from './ContactForm';

const Contact = () => {

    const contactOptions = [
        {
            icon: <FiMail />,
            title: "Email",
            contact: "marccuesta99@yahoo.com",
            url:"mailto:marccuesta99@yahoo.com"
        },
        {
            icon: <FaLinkedin />,
            title: "Linkedin",
            contact: "@marccuesta99",
            url:"https://www.linkedin.com/in/marc-cuesta-mart%C3%ADnez-1150111a4"
        },
        {
            icon: <AiOutlineWhatsApp />,
            title: "WhatsApp",
            contact: "+34 658 826 567",
            url:"https://api.whatsapp.com/send?phone=+34658826567"
        }
    ];

    return (
        <section id="contact" className="h-content pt-10 pb-20 md:py-20">

            <h4 className="text-blau-clar dark:text-blau-molt-clar text-center">Get in touch</h4>
            <h2 className="text-blau-fosc dark:text-gris-clar font-bold text-center">Contact</h2>

            <div className="flex flex-col md:flex-row gap-4 items-center xl:items-start mt-10 w-full justify-around">
                <div className="flex flex-col gap-4 items-center">
                    {contactOptions.map((option) => (
                        <ContactBox options={option} key={option.title}/>
                    ))}
                </div>
                <div className="w-full xl:w-8/12 mt-6 xl:mt-0">
                    <ContactForm />
                </div>
            </div>
        </section>
    )
}

export default Contact