import React from 'react';
import {VscVerified} from 'react-icons/vsc';

const ToolsBoxList = (props) => {

    const {title, skills} = props;

    return (
        <div className="w-content h-content py-3 px-2 bg-gris-mig/60 dark:bg-gris-clar/80 hover:shadow-md transition-all my-3 xl:mx-4 rounded-3xl md:rounded-xl">
            <h4 className="text-blau-mig/70 text-2xl text-center py-2 font-bold">{title}</h4>
            <div className="flex flex-wrap justify-center content-center py-3 md:py-4 px-1 md:px-10">
                {skills.map((s) => (
                    <div className="flex text-gris-clar dark:text-gris-mig w-content dark:gris-mig h-content px-1 md:px-2 py-1 mt-2 mb-1" key={s.skill}>
                        <div className="px-2 text-xl xl:text-3xl h-full">
                            <VscVerified />
                        </div>
                        <div className="flex flex-col h-full w-20 xl:w-28">
                            <p className="text-md xl:text-xl font-semibold">{s.skill}</p>
                            <span className="text-xs xl:text-md text-gris-clar/70 dark:text-gris-mig">{s.level}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ToolsBoxList