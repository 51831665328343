import React from 'react'

const ExperienceBox = (props) => {

    const {title, subtitle, logo} = props;

    return (
        <div className="flex flex-col justify-center items-center w-40 h-40 2xl:w-52 2xl:h-52 rounded-3xl bg-blau-fosc/70 dark:bg-blau-mig/80">
            <div className="text-2xl 2xl:text-4xl text-gris-clar">{logo}</div>
            <h4 className="font-bold text-l md:text-xl 2xl:text-2xl text-blau-molt-clar">{title}</h4>
            <h5 className="font-medium text-xs md:text-sm 2xl:text-md text-gris-clar">{subtitle}</h5>
        </div>
    )
}

export default ExperienceBox