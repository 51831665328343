import React, {useRef, useContext} from 'react';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import AlertContext from '../context/AlertContext';

const ContactForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const form = useRef();
    const {setAlert} = useContext(AlertContext);

    const onSubmit = (data, e) => {
      console.log(data);
      e.preventDefault();
      emailjs.sendForm('service_t7bo00l', 'template_us6hlmp', form.current, 'faQMD55QPQ1TTITYj')
        .then((result) => {
            setAlert({
              message: "The email has been sent!",
              visible: true,
              type: 'success'
            })
        }, (error) => {
            console.log(error.text);
        });
    };

    const onError = (data, e) => {
        setAlert({
          message: "Ups! Something went wrong!",
          visible: true,
          type: 'error'
        })
    };
  
    return (
      <form ref={form} className="flex flex-col w-full gap-3" onSubmit={handleSubmit(onSubmit, onError)}>
        <input className="w-full py-3 px-4 xl:px-6 xl:py-4 bg-fons-clar dark:bg-fons-fosc border-solid border-2 border-blau-fosc/60 dark:border-blau-clar text-md text-blau-fosc/80 dark:text-blau-molt-clar text-sm xl:text-md rounded-md my-2" placeholder="Your Full Name" {...register("name")} />
        <div className="flex flex-col gap-0">
          <input className={`w-full py-3 px-4 xl:px-6 xl:py-4 bg-fons-clar dark:bg-fons-fosc border-solid border-2  ${errors.email ? "border-alert-error-text dark:border-alert-error text-alert-error-text/80 dark:text-alert-error" : "border-blau-fosc/60 dark:border-blau-clar text-blau-fosc/80 dark:text-blau-molt-clar"} text-sm xl:text-md rounded-md my-2`} placeholder="Your Email" {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })} />
          {errors.email && <span className="ml-3 text-sm text-alert-error-text/80 dark:text-alert-error/80 font-semibold relative">You must enter a valid email address</span>}
        </div>
        <textarea className="w-full h-36 xl:h-72 py-3 px-4 xl:px-6 xl:py-4 bg-fons-clar dark:bg-fons-fosc border-solid border-2 border-blau-fosc/60 dark:border-blau-clar text-md text-blau-fosc/80 dark:text-blau-molt-clar text-sm xl:text-md  rounded-md my-2" placeholder="Your Message" {...register("message")} />
        <input type="submit" value="Contact me!" className="self-center xl:self-end mr-5 text-center mt-4 px-10 py-2 leading-5 md:leading-normal md:px-6 md:py-2 md:w-60 md:h-11 rounded-xl border-solid border border-gris-mig/50 hover:border-solid hover:border hover:border-gris-mig/40 bg-gris-clar/90 text-blau-fosc font-bold transition-all cursor-pointer"/>
      </form>
    );
}

export default ContactForm