import React from 'react';
import CV from '../../assets/Marc_Cuesta_Martínez_CV.pdf';

const CTA = () => {
    return (
        <div className="w-100 flex justify-center md:justify-start mt-6 gap-4">
            <a href={CV} download className="px-8 py-3 border border-solid rounded-md border-blau-mig dark:border-blau-molt-clar text-blau-mig dark:text-blau-molt-clar hover:text-gris-clar hover:bg-blau-mig transition-all">Download CV</a>
            <a href="#contact" className="px-8 py-3 text-gris-clar bg-blau-mig dark:bg-blau-clar rounded-md hover:bg-blau-fosc transition-all">Let's Talk</a>
        </div>
    )
}

export default CTA