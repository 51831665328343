import React, { useState } from 'react';
import {AiOutlineHome, AiOutlineUser, AiOutlineFundProjectionScreen} from 'react-icons/ai';
import {BiBook, BiMessageSquareDetail} from 'react-icons/bi';


const Nav = () => {

    const [active, setActive] = useState("#header");

   /*  window.addEventListener("scroll", () => {
        console.log(document.body.scrollTop, document.body.offsetTop, document.body.clientHeight);
    }) */

    return (
        <nav className="w-content bg-blau-mig/40 dark:bg-blau-clar/80 py-2 md:py-3 px-3 md:px-7 fixed z-1 left-2/4 -translate-x-2/4 bottom-8 flex gap-4 rounded-full backdrop-blur-xl shadow-md">
            <a className={`bg-transparent p-3 md:p-4 rounded-full text-gris-clar text-lg hover:bg-blau-fosc/40 ${active === "#header" ? "bg-blau-fosc/50" : ""} transition-all`} onClick={() => setActive("#header")} href="#header"><AiOutlineHome /></a>
            <a className={`bg-transparent p-3 md:p-4 rounded-full text-gris-clar text-lg hover:bg-blau-fosc/40 ${active === "#about" ? "bg-blau-fosc/50" : ""} transition-all`} onClick={() => setActive("#about")} href="#about"><AiOutlineUser /></a>
            <a className={`bg-transparent p-3 md:p-4 rounded-full text-gris-clar text-lg hover:bg-blau-fosc/40 ${active === "#experience" ? "bg-blau-fosc/50" : ""} transition-all`} onClick={() => setActive("#experience")} href="#experience"><BiBook /></a>
            <a className={`bg-transparent p-3 md:p-4 rounded-full text-gris-clar text-lg hover:bg-blau-fosc/40 ${active === "#portfolio" ? "bg-blau-fosc/50" : ""} transition-all`} onClick={() => setActive("#portfolio")} href="#portfolio"><AiOutlineFundProjectionScreen /></a>
            <a className={`bg-transparent p-3 md:p-4 rounded-full text-gris-clar text-lg hover:bg-blau-fosc/40 ${active === "#contact" ? "bg-blau-fosc/50" : ""} transition-all`} onClick={() => setActive("#contact")} href="#contact"><BiMessageSquareDetail /></a>
        </nav>
    )
}

export default Nav 