import React from 'react';
import {VscVerified} from 'react-icons/vsc';

const SkillsBoxList = (props) => {

    const {title, skills} = props;

    return (
        <div className="w-full h-content py-4 px-1 bg-blau-mig/60 hover:shadow-md transition-all my-4 xl:mx-4 rounded-3xl md:rounded-xl">
            <h4 className="text-blau-molt-clar text-2xl text-center py-2 font-bold">{title}</h4>
            <div className="flex flex-wrap justify-center content-center py-3 md:py-4 px-1 md:px-10">
                {skills.map((s) => (
                    <div className="flex text-gris-clar w-content h-content px-1 md:px-2 py-1 m-2" key={s.skill}>
                        <div className="px-2 text-xl xl:text-3xl h-full">
                            <VscVerified />
                        </div>
                        <div className="flex flex-col h-full w-20 xl:w-28">
                            <p className="text-md xl:text-xl font-semibold">{s.skill}</p>
                            <span className="text-xs xl:text-md text-gris-clar/70">{s.level}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SkillsBoxList