import React from 'react';
import './topbar.css';

const Topbar = (props) => {

    const {dark ,setDark} = props;

    const handleChange = (e) => {
        let checked = e.target.checked;
        if (checked) {
            localStorage.theme = 'dark';
            setDark(true);
        } else {
            localStorage.theme = 'light'
            setDark(false);
        }
    }

    return (
        <div id="header" className="flex flex-col pt-10 w-screen">
            <div className="flex justify-between xl:self-end mr-8 md:mr-24">
                <p className="w-36 text-sm xl:text-lg text-blau-fosc dark:text-gris-clar mx-8 md:ml-24 self-center">November 9, 1999</p>
                <label className="relative inline-block w-14 h-9">
                    <input type="checkbox" className="opacity-0 w-0 h-0" onChange={handleChange} defaultChecked={dark}/>
                    <span className="slider absolute cursor-pointer top-0 right-0 left-0 bottom-0 border-solid border-2 border-blau-fosc rounded-full transition-all
                                     before:absolute before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-gris-mig before:rounded-full before:transition-all"></span>
                </label>
            </div>
            
            <div className="mt-4 h-0.5 w-screen bg-gris-mig/60"></div>
            
        </div>
    )
}

export default Topbar